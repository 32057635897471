import React from "react";
import { ContentContainer, Title } from "./Abonnement";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { getInreesId, getUser, setAuthToken, setInreesId, setInreesToken } from "../store/reducers/authSlice";
import { getLoginRedirectURL, useAuthToken, useIsLoggedIn, useLogUserIn } from "../hooks/auth";
import { useDispatch, useSelector } from "react-redux";
import { useGetContactSubjectsQuery, useSendContactMessageMutation } from "../store/api/contact";
import InexButton from "../components/InexButton";
import ContactUserForm from "../components/contact/ContactUserForm";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { setShowSnackbar, setSnackbarSeverity, setSnackbarText } from "../store/reducers/snackbarSlice";
import { useGetUserQuery } from "../store/api/user";
import useTabTitle from "../hooks/useTabTitle";

const Contact = () => {
	useTabTitle({ customTitle: "Contact" });

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useSelector(getUser);
	const { data: subjects } = useGetContactSubjectsQuery();
	const [sendContactMessage] = useSendContactMessageMutation();
	const inreesId = useSelector(getInreesId);
	const isLoggedIn = useIsLoggedIn();
	const recaptchaRef = React.createRef();
	useLogUserIn();
	const token = useAuthToken();
	useGetUserQuery({}, { skip: !token });

	const urlParams = new URLSearchParams(window.location.search);
	const idsujet = (urlParams.has("topic") && parseInt(urlParams.get("topic"))) ? parseInt(urlParams.get("topic")) : 0;

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
		watch,
		resetField,
	} = useForm({
		defaultValues: {
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			subject: idsujet,
			message: "",
			rappel: false,
			telrappel: "",
		},
	});

	const _goToFaq = () => {
		navigate("/faq");
	};

	const _goToWeTransfer = () => {
		window.open(`https://wetransfer.com`, "_blank").focus();
	};

	const logOut = () => {
		const cookies = new Cookies();
		cookies.remove("INEXPLORE_USER", {
			path: "/",
			domain: ".inexplore.com",
		});
		cookies.remove("INEXPLORE_TOKEN", {
			path: "/",
			domain: ".inexplore.com",
		});
		cookies.remove("INEXPLORE_ID", {
			path: "/",
			domain: ".inexplore.com",
		});
		dispatch(setInreesId(null));
		dispatch(setInreesToken(null));
		dispatch(setAuthToken(null));
		const redirectUrl = getLoginRedirectURL();
		window.location.href = redirectUrl;
	};

	const _sendContact = async (data) => {
		const RecaptchaToken = await recaptchaRef.current.executeAsync();
		if (!!isLoggedIn) {
			sendContactMessage({
				idinexplore: inreesId,
				email: user?.email,
				prenom: user?.prenom,
				nom: user?.nom,
				portable: user?.portable,
				idsujet: data?.subject,
				message: data.message,
				rappel: data?.rappel,
				telrappel: data?.telrappel,
				RecaptchaToken,
			});
		} else {
			sendContactMessage({
				idinexplore: 0,
				email: data?.email,
				prenom: data?.firstName,
				nom: data?.lastName,
				portable: data?.phone,
				idsujet: data?.subject,
				message: data.message,
				rappel: data?.rappel,
				telrappel: data?.telrappel,
				RecaptchaToken,
			});
		}
		dispatch(setShowSnackbar(true));
		dispatch(setSnackbarSeverity("success"));
		dispatch(setSnackbarText("Votre message a été envoyé."));
		reset();
	};

	const _onCaptchaUpdate = (value) => {
		console.log("On change => ", value);
	};

	const onSubmit = (data) => {
		_sendContact(data);
	};

	return (
		<ContentContainer container item className={"content-background"}>
			<Grid
				container
				item
				xs={12}
				sx={{
					marginBottom: (theme) => theme.spacing(2),
				}}
				spacing={3}
			>
				<Grid item xs={12}>
					<Title>Envoyez-nous un message</Title>
				</Grid>
				<Grid item xs={12}>
					<Typography fontFamily={"Branding Bold"} fontSize={20} color={"#182B43"} lineHeight={"23px"}>
						Avant de nous contacter, nous vous proposons de consulter notre{" "}
						<Typography
							onClick={_goToFaq}
							fontFamily={"Branding Bold"}
							fontSize={20}
							color={"#EE848E"}
							display={"inline"}
							sx={{ cursor: "pointer" }}
							variant={"span"}
						>
							FAQ.
						</Typography>{" "}
						Elle vous permettra peut-être de trouver une réponse plus rapidement.
					</Typography>
				</Grid>
				{!!isLoggedIn && (
					<Grid item xs={12}>
						<Typography
							fontFamily={"Branding SemiBold"}
							fontSize={16}
							color={"#182B43"}
							lineHeight={"18px"}
						>
							Vous êtes actuellement connecté à votre compte « {user?.prenom} », vous pouvez vous{" "}
							<Typography
								onClick={logOut}
								fontFamily={"Branding SemiBold"}
								fontSize={16}
								color={"#EE848E"}
								display={"inline"}
								sx={{ cursor: "pointer", textDecoration: "underline" }}
								variant={"span"}
							>
								déconnecter
							</Typography>{" "}
							.
						</Typography>
					</Grid>
				)}
				<ContactUserForm
					subjects={subjects}
					control={control}
					errors={errors}
					watch={watch}
					resetField={resetField}
				/>
				<form>
					<ReCAPTCHA
						ref={recaptchaRef}
						sitekey="6LfBRPgmAAAAAJx1ZCrzhWABZXJNF6uVjcTp-Y7D"
						onChange={_onCaptchaUpdate}
						size="invisible"
						asyncScriptOnLoad={() => console.log("Loaded")}
					/>
				</form>
				<Grid item container spacing={2}>
					<Grid item>
						<Typography fontFamily={"Branding SemiBold"} fontSize={16}>
							Veuillez noter que l'envoi de pièces jointes peut s’effectuer via{" "}
							<Typography
								onClick={_goToWeTransfer}
								display={"inline"}
								fontFamily={"Branding SemiBold"}
								fontSize={16}
								color={"#EE848E"}
								sx={{ cursor: "pointer", textDecoration: "underline" }}
								variant={"span"}
							>
								Wetransfer
							</Typography>
						</Typography>
						{/*<Typography fontFamily={"Branding SemiBold"} fontSize={16}>
							Vous trouverez les indications nécessaires dans notre{" "}
							<Typography
								onClick={_goToFaq}
								display={"inline"}
								fontFamily={"Branding SemiBold"}
								fontSize={16}
								color={"#EE848E"}
								sx={{ cursor: "pointer", textDecoration: "underline" }}
								variant={"span"}
							>
								foire au questions
							</Typography>
						</Typography>*/}
					</Grid>
				</Grid>
				<Grid item>
					<InexButton
						text={"Envoyer le message"}
						onClick={handleSubmit(onSubmit)}
						sx={{
							width: "100%",
							height: "50px",
						}}
					/>
				</Grid>
			</Grid>
		</ContentContainer>
	);
};

export default Contact;
